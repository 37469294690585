<template>
  <div v-if="$userInfo.isSupportUser"
    class="tile is-parent pl-1">
    <section class="tile is-child box">
      <div>
        <h3 class="subtitle">RepX Stages Setting</h3>
      </div>
      <div class="columns">
        <div class="column">
          <span class="subtitle">Repair Stage</span>
          <div v-for="(stage, key) in repxStages"
            :key="key">
            <div v-for="(input, index) in repxStageSettings[key]"
              :key="index">
              <div class="pt-2">
                <div class="label">{{ stage }}</div>
                <Multiselect v-model="repxStageSettings[key][index]"
                  :options="jobStagesAvailable"
                  :hide-selected="true"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :show-labels="false"
                  :preserve-search="true"
                  placeholder="Please Select At one Job stage"
                  track-by="compositeKey"
                  label="compositeName" />
              </div>
            </div>
          </div>
          <!-- <pre>{{ repxStageSettings }}</pre> -->
          <div class="buttons mt-5">
            <button class="button is-primary tooltip"
              data-tooltip="Save"
              @click="updateRepxStagesSetting">
              <span class="icon">
                <i class="mdi mdi-content-save mdi-20px" />
              </span>
              <span>Save</span>
            </button>
          </div>
        </div>
        <div class="column">
          <h3 class="subtitle">Available Job Stages</h3>
          <!-- <pre>{{ jobStages }}</pre> -->
          <!-- <pre>{{ jobNSubJobStages }}</pre> -->
          <ul>
            <li v-for="item in jobStagesAvailable"
              :key="item.compositeKey">
              <span class="multiselect__tag mt-2">
                {{ item.compositeName }}
              </span>
            </li>
          </ul>

        </div>
      </div>

    </section>
  </div>
  <div v-else
    class="is-flex is-justify-content-center pt-5 ">
    <div class="notification is-danger is-flex is-justify-content-center is-size-4"
      style="width: 50%">
      You do not have permissions on this page
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import QuoteService from '../../quote/QuoteService'
import CompanyService from '../../company/CompanyService'
import { RepxStages } from '@/enums'

export default {
  name: 'CompanyRepxStagesSettings',

  components: {
    Multiselect
  },

  data: () => {
    return {
      // users: [],
      // usersWithAccess: [],
      repxStageSettings: {},
      repxRepairStages: [],
      // jobStages: [],
      usedStages: [],
      jobNSubJobStages: []
    }
  },
  computed: {
    repxStages() {
      return RepxStages
    },
    jobStagesAvailable() {
      var finds = []
      for (let key in this.repxStages) {
        if (this.repxStageSettings[key] != undefined && this.repxStageSettings[key][0] != '')
          this.repxStageSettings[key][0].forEach((item) => {
            finds.push(item)
          })
      }

      var filterItem = this.jobNSubJobStages.filter((stage) => !finds.some((a) => a.compositeKey == stage.compositeKey))
      return filterItem
    },
    serilaizeData() {
      const serializedData = Object.entries(this.repxStageSettings).map(([key, value]) => ({
        StageName: key,
        Stages: value
      }))
      // Output serializedData in console to see the structure
      return serializedData
    }
  },
  async created() {
    // await this.fetchUsers()
    // await this.fetchUsersWithXeroAccess()
    // await this.fetchJobStages()
    await this.fetchJobNSubJobStages()
    await this.fetchRepxRepairStages()
    // Initialize repxStageSettings for each stage
    //this.populateRepxStages()
   this.populateRepxStages()
  },
  methods: {
    // async fetchJobStages() {
    //   this.jobStages = await QuoteService.getJobStagesProto()
    //   // console.log(this.jobStages)
    // },
    async fetchJobNSubJobStages() {
      this.jobNSubJobStages = await QuoteService.getJobNSubJobStages()
      // console.log(this.jobStages)
    },
    async fetchRepxRepairStages() {
      this.repxRepairStages = await CompanyService.getRepxStagesSetting()
    },
    async populateRepxStages() {
      for (let key in this.repxStages) {
        var repxStageItems = this.repxRepairStages.filter((o) => o.repairStage == key)
        if (repxStageItems.length > 0) {
          const items = this.jobNSubJobStages.filter((stage) => repxStageItems.some((a) => a.compositeKey == stage.compositeKey))
          var container = []
          container.push(items)
          this.$set(this.repxStageSettings, key, container)
        } else this.$set(this.repxStageSettings, key, [''])
      }
    },
    async updateRepxStagesSetting() {
      // if (!user) return this.$notification.error('Error', 'Please select at least one user')
      // const userIds = user.map((u) => ({ userId: u.userId }))
      try {
        this.$showSpinner()
        // var data = this.serilaizeData
        // console.log(data)
        await CompanyService.updateRepxStagesSetting(this.serilaizeData)
        this.$hideSpinner()
        this.$toast.open({
          message: 'Settings Updated',
          type: 'is-success',
          position: 'is-top',
          queue: false
        })
        // this.$notification.success('Success', 'Settings Updated')
      } catch (error) {
        this.$hideSpinner()
        this.$toast.open({
          message: 'Failed Settings Updated',
          type: 'is-danger',
          position: 'is-top',
          queue: false
        })
        // this.$notification.error('Error', 'Failed Settings Updated')
      }
    }
  }
}
</script>
