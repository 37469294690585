import { TableColumn } from '@/classes'

const OverrideBusinessHoursColumns = [
  new TableColumn('Date', 'date', true, 'desc', 'left'),
  new TableColumn('Bus. Day', 'isBusinessDay', true, 'desc', 'centered'),
  new TableColumn('Slot', 'productionSlot', true, 'desc', 'right'),
  new TableColumn('Open Time', 'openTime', false, 'desc', 'left'),
  new TableColumn('Close Time', 'closeTime', false, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { OverrideBusinessHoursColumns }
