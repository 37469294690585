<template>
  <div v-if="$company.setting"
    class="tile is-ancestor is-parent">
    <div class="tile is-parent pl-1">
      <section class="tile is-child box businessHours">
        <div class="subtitle">Business Hours</div>

        <div class="field is-hidden">
          <div class="columns">
            <div class="column">
              <span class="tag is-info is-light is-pulled-right has-text-weight-bold">** Each slot is duration of 30 minutes.</span>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Time Slot (minutes)</label>
          </div>
          <div class="field">
            <div class="control">
              <the-mask class="input"
                placeholder="e.g. 30"
                v-model="businessHourTimeSlot"
                :mask="['###']" />
            </div>
          </div>
        </div>

        <div class="field">
          <div class="columns field">
            <div class="column" />
            <div class="column has-text-weight-bold is-2">
              Slots
            </div>
            <div class="column has-text-weight-bold">
              Start Time
            </div>
            <div class="column has-text-weight-bold">
              Close Time
            </div>
          </div>
        </div>
        <div class="field">
          <div v-for="(day, index) in daysOfWeek"
            :key="index"
            class="columns">
            <div class="column">
              <div class="is-flex is-flex-direction-column">
                <div class="control"
                  style="width: 5em;">
                  <div class="pretty p-switch p-fill">
                    <input type="checkbox"
                      @change="handleToggleBusinessDay(day)"
                      :checked="day.isBusinessDay">
                    <div class="state p-primary">
                      <label>{{ day.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <label :for="day.name"
              class="label">{{ day.name }}</label> -->
            </div>
            <div class="column is-2">
              <div class="field">
                <div class="control">
                  <the-mask class="input"
                    placeholder="e.g. 6"
                    v-model="hours[day.name].productionSlot"
                    :mask="['##']"
                    content="Required. Must be greater than 0 and less than 99."
                    :disabled="!day.isBusinessDay" />
                </div>
              </div>
            </div>
            <div class="column">
              <v-date-picker v-model="hours[day.name].open"
                ref="datepicker"
                mode="time"
                :minute-increment="5"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                class="is-fullwidth"
                :model-config="hours[day.name].open ? {} : modelConfig"
                :timezone="$filters.getIanaTimezone()"
                :disabled="!day.isBusinessDay">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field has-addons">
                    <div class="control is-expanded has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Start Time"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly
                        ref="day.name"
                        :disabled="!day.isBusinessDay">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="column">
              <v-date-picker v-model="hours[day.name].close"
                ref="datepicker"
                mode="time"
                :minute-increment="5"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                class="is-fullwidth"
                :model-config="hours[day.name].close ? {} : modelConfig"
                :timezone="$filters.getIanaTimezone()"
                :disabled="!day.isBusinessDay">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field has-addons">
                    <div class="control is-expanded has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Close Time"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly
                        ref="day.name"
                        :disabled="!day.isBusinessDay">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
        <div class="fields">
          <div class="buttons">
            <button class="button is-primary tooltip"
              data-tooltip="Save"
              @click="saveHours">
              <span class="icon">
                <i class="mdi mdi-content-save mdi-20px" />
              </span>
              <span>Save</span>
            </button>
          </div>
        </div>
      </section>
    </div>
    <div class="tile is-parent pl-1">
      <section class="tile is-child box">
        <div class="subtitle">Override Business Hours Listing</div>
        <company-override-business-hours />
      </section>
    </div>
  </div>

</template>

<script>
import CompanyService from '../CompanyService'
import CompanyOverrideBusinessHours from './CompanyOverrideBusinessHours.vue'
import store from '@/store'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'

export default {
  name: 'CompanyBusinessHours',
  components: {
    CompanyOverrideBusinessHours
  },
  data() {
    return {
      businessHours: [],
      daysOfWeek: [
        { name: 'Monday', isBusinessDay: false },
        { name: 'Tuesday', isBusinessDay: false },
        { name: 'Wednesday', isBusinessDay: false },
        { name: 'Thursday', isBusinessDay: false },
        { name: 'Friday', isBusinessDay: false },
        { name: 'Saturday', isBusinessDay: false },
        { name: 'Sunday', isBusinessDay: false }
      ],
      hours: {
        Monday: { open: '', close: '', productionSlot: null },
        Tuesday: { open: '', close: '', productionSlot: null },
        Wednesday: { open: '', close: '', productionSlot: null },
        Thursday: { open: '', close: '', productionSlot: null },
        Friday: { open: '', close: '', productionSlot: null },
        Saturday: { open: '', close: '', productionSlot: null },
        Sunday: { open: '', close: '', productionSlot: null }
      },
      initialDayOfWeek: null,
      initialHours: null,
      selectedDates: {
        start: null,
        end: null
      },
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        // start: {
        //   timeAdjust: '08:00:00'
        // },
        // end: {
        //   timeAdjust: '08:00:00'
        // }
      },
      isValid: false,
      initialTimeSlot: null,
      businessHourTimeSlot: null
    }
  },
  computed: {
    company() {
      const company = store.getters['company/company']
      return company
    }
  },
  watch: {
    'hours.Monday.productionSlot': 'autoFillSlot',
    'hours.Tuesday.productionSlot': 'autoFillSlot',
    'hours.Wednesday.productionSlot': 'autoFillSlot',
    'hours.Thursday.productionSlot': 'autoFillSlot',
    'hours.Friday.productionSlot': 'autoFillSlot',
    'hours.Saturday.productionSlot': 'autoFillSlot',
    'hours.Sunday.productionSlot': 'autoFillSlot',
    'hours.Monday.open': 'autoFillOpenTime',
    'hours.Tuesday.open': 'autoFillOpenTime',
    'hours.Wednesday.open': 'autoFillOpenTime',
    'hours.Thursday.open': 'autoFillOpenTime',
    'hours.Friday.open': 'autoFillOpenTime',
    'hours.Saturday.open': 'autoFillOpenTime',
    'hours.Sunday.open': 'autoFillOpenTime',
    'hours.Monday.close': 'autoFillCloseTime',
    'hours.Tuesday.close': 'autoFillCloseTime',
    'hours.Wednesday.close': 'autoFillCloseTime',
    'hours.Thursday.close': 'autoFillCloseTime',
    'hours.Friday.close': 'autoFillCloseTime',
    'hours.Saturday.close': 'autoFillCloseTime',
    'hours.Sunday.close': 'autoFillCloseTime'
  },
  created() {
    this.refresh()
  },
  methods: {
    async refresh() {
      const datas = await CompanyService.getBusinessHours()
      for (let i = 0; i < datas.length; i++) {
        const { isBusinessDay, weekDayName, openTime, closeTime, productionSlot } = datas[i]
        const found = this.daysOfWeek.find((i) => i.name === weekDayName)
        if (found) {
          found.isBusinessDay = isBusinessDay
          this.$set(this.hours, weekDayName, { open: openTime, close: closeTime, productionSlot: productionSlot })
        }
      }
      this.initialDayOfWeek = _cloneDeep(this.daysOfWeek)
      this.initialHours = _cloneDeep(this.hours)
      this.businessHours = datas
      if (this.company.info.businessHourTimeSlot) {
        const timeSlot = this.company.info.businessHourTimeSlot
        const timeSlotInMinutes = this.timeToMinutes(timeSlot)
        this.initialTimeSlot = timeSlotInMinutes
        this.businessHourTimeSlot = timeSlotInMinutes
      }
    },
    async saveHours() {
      this.isValid = true //reset
      this.validateBusinessHourTimeSlot()
      const selectedHours = this.getSelectedHours()
      if (this.isValid) {
        const timeSpan = this.minutesToTime(this.businessHourTimeSlot)

        const hasChange = !_isEqual(this.initialTimeSlot, this.businessHourTimeSlot)
        if (hasChange) {
          const isSuccessUpdateTimeSlot = await CompanyService.updateBusinessHourTimeSlot(timeSpan)
          if (isSuccessUpdateTimeSlot) {
            await this.$store.dispatch('company/getCompanyInfo')
            this.$toast.open({
              message: 'Save Time Slot successful',
              type: 'is-success',
              position: 'is-top',
              queue: false
            })
            // this.$notification.openNotificationWithType('success', 'Save Time Slot', 'Save successful')
            this.refresh()
          } else {
            this.$toast.open({
              message: 'Save Time Slot failed',
              type: 'is-danger',
              position: 'is-top',
              queue: false
            })
            // this.$notification.openNotificationWithType('danger', 'Save Time Slot', 'Save failed')
          }
        }

        const isSuccessUpdateBusinessHours = await CompanyService.updateBusinessHours(selectedHours)
        if (isSuccessUpdateBusinessHours) {
          this.$toast.open({
              message: 'Save Business Hours successful',
              type: 'is-success',
              position: 'is-top',
              queue: false
            })
          // this.$notification.openNotificationWithType('success', 'Save Business Hours', 'Save successful')
        } else {
          this.$toast.open({
              message: 'Save Business Hours failed',
              type: 'is-danger',
              position: 'is-top',
              queue: false
            })
          // this.$notification.openNotificationWithType('danger', 'Save Business Hours', 'Save failed')
        }
      }
    },
    minutesToTime(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60)
      const minutes = Math.floor(totalMinutes % 60)
      const seconds = Math.floor((totalMinutes % 1) * 60)

      const formattedHours = String(hours).padStart(2, '0')
      const formattedMinutes = String(minutes).padStart(2, '0')
      const formattedSeconds = String(seconds).padStart(2, '0')

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    },
    timeToMinutes(timeString) {
      const [hours, minutes] = timeString.split(':').map(Number)
      const totalMinutes = hours * 60 + minutes
      return totalMinutes
    },
    validateBusinessHourTimeSlot() {
      let isValid = true
      if (!this.businessHourTimeSlot) {
        this.$toast.open({
            message: 'Business Hour Time Slot not provided.',
            type: 'is-danger',
            position: 'is-top',
            queue: false
          })
        // this.$notification.openNotificationWithType('danger', 'Error', 'Business Hour Time Slot not provided.')
        isValid = false
      }
      this.isValid = this.isValid && isValid
    },
    getSelectedHours() {
      const selectedHours = []
      let isValid = true
      this.daysOfWeek.forEach((day) => {
        if (day.isBusinessDay) {
          const { open, close, productionSlot } = this.hours[day.name]
          if (!open || !close) {
            this.$toast.open({
            message: `Day "${day.name}" is not assign start or end time.`,
            type: 'is-danger',
            position: 'is-top',
            queue: false
          })
            // this.$notification.openNotificationWithType('danger', 'Error', `Day "${day.name}" is not assign start or end time.`)
            isValid = false
          }
          //selectedHours[day.name] = this.hours[day.name]
          selectedHours.push({
            isBusinessDay: day.isBusinessDay,
            weekDay: this.getWeekdayIndex(day.name),
            openTime: open,
            closeTime: close,
            productionSlot: productionSlot
          })
        }
      })
      this.isValid = this.isValid && isValid

      return selectedHours
    },
    handleToggleBusinessDay(day) {
      const { name } = day
      const found = this.daysOfWeek.find((i) => i.name === name)
      if (found) {
        found.isBusinessDay = !found.isBusinessDay
        if (!found.isBusinessDay) {
          this.$set(this.hours, name, { open: '', close: '', productionSlot: '' })
        }
      }
    },
    getWeekdayIndex(weekdayName) {
      var weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      var index = weekdays.indexOf(weekdayName)
      return index
    },
    autoFillSlot(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.daysOfWeek.forEach((day) => {
          if (day.isBusinessDay) {
            if (this.hours[day.name].isEmpty || this.hours[day.name].productionSlot == null || this.hours[day.name].productionSlot == '') {
              this.hours[day.name].productionSlot = newVal
              this.hours[day.name].isEmpty = true
            }
          }
        })
      }
    },
    autoFillOpenTime(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.daysOfWeek.forEach((day) => {
          if (day.isBusinessDay) {
            if (this.hours[day.name].isEmpty || this.hours[day.name].open == null || this.hours[day.name].open == '') {
              this.hours[day.name].open = newVal
              this.hours[day.name].isEmpty = true
            }
          }
        })
      }
    },
    autoFillCloseTime(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.daysOfWeek.forEach((day) => {
          if (day.isBusinessDay) {
            if (this.hours[day.name].isEmpty || this.hours[day.name].close == null || this.hours[day.name].close == '') {
              this.hours[day.name].close = newVal
              this.hours[day.name].isEmpty = true
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  margin-bottom: 15px;
}
.time-pickers {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 5px;
}
.control {
  flex: 1;
}
.control + .control {
  margin-left: 10px;
}
.businessHours .column {
  padding-top: 0;
  padding-bottom: 0;
}
.overrideBusinessHours .column {
  padding-bottom: 0;
}

</style>
