<template>
  <div :active.sync="isActive">
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child businessHours">
          <div class="subtitle">Override Business Hours</div>

          <div class="columns field">
            <div class="column">
              <label class="label">Selected Date</label>
              <div class="control">
                <v-date-picker
                  v-model="entity.date"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  @input="selectedDateInputEvent"
                  :timezone="$filters.getIanaTimezone()"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          :disabled="!entity.isNew"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
                <span class="help is-danger"
                  v-if="!$v.entity.date.required">Date is required</span>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="columns">
              <div class="column" />
              <div class="column has-text-weight-bold is-2">Slots</div>
              <div class="column has-text-weight-bold">Open Time</div>
              <div class="column has-text-weight-bold">Close Time</div>
            </div>
          </div>
          <div class="field">
            <div class="columns">
              <div class="column">
                <div class="is-flex is-flex-direction-column">
                  <div class="control"
                    style="width: 5em">
                    <div class="pretty p-switch p-fill">
                      <input type="checkbox"
                        @change="handleToggleBusinessDay(entity)"
                        :checked="entity.isBusinessDay">
                      <div class="state p-primary">
                        <label>Business Hours</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-2">
                <div class="field">
                  <div class="control">
                    <the-mask
                      class="input"
                      placeholder="e.g. 6"
                      v-model="entity.productionSlot"
                      :mask="['##']"
                      content="Required. Must be greater than 0 and less than 99."
                      :disabled="!entity.isBusinessDay"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <v-date-picker
                  v-model="entity.openTime"
                  ref="datepicker"
                  mode="time"
                  :minute-increment="30"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  class="is-fullwidth"
                  :model-config="entity.openTime ? {} : modelConfig"
                  :timezone="$filters.getIanaTimezone()"
                  :disabled="!entity.isBusinessDay"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field has-addons">
                      <div class="control is-expanded has-icons-left">
                        <input
                          type="text"
                          class="input"
                          placeholder="Start Time"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly
                          ref="entity.name"
                          :disabled="!entity.isBusinessDay"
                        >
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
                <span class="help is-danger"
                  v-if="!$v.entity.openTime.required">Open Time is required</span>
              </div>
              <div class="column">
                <v-date-picker
                  v-model="entity.closeTime"
                  ref="datepicker"
                  mode="time"
                  :minute-increment="30"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  class="is-fullwidth"
                  :model-config="entity.closeTime ? {} : modelConfig"
                  :timezone="$filters.getIanaTimezone()"
                  :disabled="!entity.isBusinessDay"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field has-addons">
                      <div class="control is-expanded has-icons-left">
                        <input
                          type="text"
                          class="input"
                          placeholder="Close Time"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly
                          ref="entity.name"
                          :disabled="!entity.isBusinessDay"
                        >
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
                <span class="help is-danger"
                  v-if="!$v.entity.closeTime.required">Close Time is required</span>
              </div>
            </div>
          </div>
          <div class="field mt-6">
            <span class="tag is-info is-light is-pulled-right">** Save without enable business hours will treat as off day.</span>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
// import { ConfirmModal } from '@/components/BulmaModal'
// import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { FocusInserted } from '@/components/directives'
// import { mapGetters } from 'vuex'
// import VueNumeric from '@/components/VueNumeric'
// import EventBus from '@/components/EventBus'
import CompanyService from '../CompanyService'
import OverrideBusinessHoursValidation from './OverrideBusinessHoursValidation'
import { EventHubTypes } from '@/enums'

export default {
  name: 'OverrideBusinessHours',
  // inject: ['$vv'],
  components: {
    // ConfirmModal,
    // VueNumeric
  },
  directives: {
    FocusInserted
  },
  mixins: [OverrideBusinessHoursValidation],
  props: {
    overrideBusinessHoursId: {
      type: String
    },
    active: {
      type: Boolean,
      default: false
    },
    // value: null,
    isNew: {
      type: Boolean,
      require: true
    },
    enableSave: {
      type: Boolean
    }
  },
  data() {
    return {
      entity: {
        companyOverrideBusinessHoursId: '',
        date: null,
        isBusinessDay: null,
        productionSlot: 0,
        openTime: null,
        closeTime: null
      },
      isActive: this.active || false,
      isEnableSave: false,
      selectedDate: null,

      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        // start: {
        //   timeAdjust: '08:00:00'
        // }
        // end: {
        //   timeAdjust: '08:00:00'
        // }
      }
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error
    }
  },
  watch: {
    // 'entity.openTime': {
    //   handler: function (newVal, oldVal) {
    //     if (newVal && newVal.getTime() !== oldVal.getTime()) {
    //       if (this.entity.date) {
    //         const clonedDate = new Date(newVal)
    //         clonedDate.setFullYear(this.entity.date.getFullYear())
    //         clonedDate.setMonth(this.entity.date.getMonth())
    //         clonedDate.setDate(this.entity.date.getDate())
    //         this.entity.openTime = clonedDate
    //       }
    //     }
    //   },
    // }
  },
  async created() {
    await this.getEntity()
  },
  mounted() {},
  methods: {
    async getEntity() {
      console.log(this.overrideBusinessHoursId)
      this.entity = this.isNew
        ? await CompanyService.getNewEntityOverrideBusinessHours()
        : await CompanyService.getEntityOverrideBusinessHours(this.overrideBusinessHoursId)
    },
    close() {
      this.isActive = false
      this.clear()
    },
    clear() {
      this.selectedDate = null
      this.entity.companyOverrideBusinessHoursId = ''
      this.entity.date = null
      this.entity.isBusinessDay = false
      this.entity.productionSlot = 0
      this.entity.openTime = null
      this.entity.closeTime = null
    },
    async save() {
      this.$v.$touch()
      //const title = 'Override Business Hours'

      if (this.validateError) {
        //this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        this.$toast.open({
          message: 'Validation errors. Please fix before saving',
          type: 'is-danger',
          position: 'is-top',
          queue: false
        })
        return false
      }

      this.$showSpinner('Saving...')
      var result = await CompanyService.postOverrideBusinessHours(this.entity)
      if (result.isSuccess) {
        //this.$notification.success('Override Business Hours', this.entity.isDeleted ? 'Override Business Hours set to In-Active' : 'Save successful')
        this.$toast.open({
          message: this.entity.isDeleted ? 'Override Business Hours set to In-Active' : 'Save successful',
          type: 'is-success',
          position: 'is-top',
          queue: false
        })
      }
      else {
        this.$toast.open({
          message: result.message || 'Open time or Close time is not allowed. Please select another time',
          type: 'is-danger',
          position: 'is-top',
          queue: false
        })
      }
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntitySaved)
      this.$emit('save')
    },
    handleToggleBusinessDay(entity) {
      this.entity.isBusinessDay = !entity.isBusinessDay
      if (!this.entity.isBusinessDay) {
        this.entity.productionSlot = 0
        this.entity.openTime = null
        this.entity.closeTime = null
      }
    },
    selectedDateInputEvent(event) {
      //this.selectedDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  margin-bottom: 15px;
}
.time-pickers {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 5px;
}
.control {
  flex: 1;
}
.control + .control {
  margin-left: 10px;
}
.input {
  width: 100%;
}
.button {
  margin-top: 20px;
  width: 100%;
}
.businessHours .column {
  padding-top: 0;
  padding-bottom: 0;
}
.overrideBusinessHours .column {
  padding-bottom: 0;
}
.is-divider {
  margin: 1.2em 0;
}
.desc-width {
  width: 50%;
}
:global(.vc-time-picker .vc-date) {
  display: none;
}
</style>
