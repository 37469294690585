<template>
  <div class="box expandable-box no-shadow has-border p-1"
    :class="{'expanded-bg': isExpanded}">
    <!-- Header with icon and title -->
    <div class="box-header is-align-items-center"
      @click="toggle">
      <div class="columns is-fullwidth">
        <div class="column is-half">
          <span class="icon">
            <i :class="isExpanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'" />
          </span>
          <span class="has-text-weight-bold">{{ isExpanded ? expendedTitle : title }}</span>
        </div>
        <div class="column is-half">
          <span class="">{{ isExpanded ? expendedSubTitle : subTitle }}</span>
        </div>
      </div>
    </div>
    <transition name="expand">
      <div v-show="isExpanded"
        class="content"
        :style="expandedStyle">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ExpendableDiv',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    expendedTitle: {
      type: String,
      default: ''
    },
    expendedSubTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    expandedStyle() {
      return {
        // maxHeight: this.isExpanded ? '1000px' : '0'  // Dynamically adjust max-height
        // overflow: 'hidden'  // Ensure content doesn't overflow
      }
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  background-color: white;
}
.expanded-bg {
  background-color: #e9ecef; /* Light blue background when expanded */
}
.no-shadow {
  box-shadow: none;
}
.has-border {
  border: 1px solid #dbdbdb;
}
.no-padding {
  padding: 0;
}
.expandable-box {
  cursor: pointer;
}
.expandable-box:hover {
  background-color: #f5f5f5; /* Optional hover effect */
}
/* Transition for expanding/collapsing content */
.expand-enter-active, .expand-leave-active {
  transition: max-height 0.3s ease;
}
.expand-enter, .expand-leave-to {
  max-height: 0;
  overflow: hidden;

}
</style>