import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      entity: {
        date: null,
        isBusinessDay: null,
        openTime: null,
        closeTime: null
      }
    }
  },
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.date', 'entity.openTime', 'entity.closeTime'],
    entity: {
      date: {
        required
      },
      openTime: {
        required: requiredIf(function (model) {
          return !!(model.isBusinessDay)
        })
      },
      closeTime: {
        required: requiredIf(function (model) {
          return !!(model.isBusinessDay)
        })
      }
    }
  }
}
