<template>
  <div v-if="$userInfo">
    <div class="columns is-gapless">
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div class="menu">
              <p class="menu-label">
                <span class="title is-6">
                  Filters
                </span>
              </p>
              <ul class="menu-list">
                <li>
                  <div class="field">
                    <v-date-picker v-model="filter.fromDate"
                      :masks="formats"
                      :attributes="attrs"
                      :model-config="modelConfig"
                      :locale="$userInfo.locale"
                      timezone="UTC">
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="field">
                          <div class="control has-icons-left">
                            <input type="text"
                              class="input"
                              placeholder="Date From..."
                              :value="inputValue"
                              v-on="inputEvents">
                            <span class="icon is-small is-left">
                              <i class="mdi mdi-calendar mdi-18px" />
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-date-picker>
                  </div>
                  <div class="field">
                    <v-date-picker v-model="filter.toDate"
                      :masks="formats"
                      :attributes="attrs"
                      :model-config="modelConfig"
                      :locale="$userInfo.locale"
                      timezone="UTC">
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="field">
                          <div class="control has-icons-left">
                            <input type="text"
                              class="input"
                              placeholder="Date To..."
                              :value="inputValue"
                              v-on="inputEvents">
                            <span class="icon is-small is-left">
                              <i class="mdi mdi-calendar mdi-18px" />
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-date-picker>
                  </div>
                </li>
              </ul>
              <hr>
              <div class="field is-grouped is-pulled-right">
                <div class="control">
                  <button class="button is-primary"
                    @click="onFilterClick">
                    <span class="icon">
                      <i class="mdi mdi-magnify mdi-18px" />
                    </span>
                    <span>Filter</span>
                  </button>
                </div>
                <div class="control">
                  <button class="button"
                    @click="onResetClick">
                    <span class="icon">
                      <i class="mdi mdi-refresh mdi-18px" />
                    </span>
                    <span>Reset</span>
                  </button>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="columns is-gapless">

      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">

            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entities"
                :key="entity.companyOverrideBusinessHoursId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <a @click="handleEdit(entity.companyOverrideBusinessHoursId)">
                    {{ $filters.formatDateLocale(entity.date, $userInfo.locale) }}
                  </a>
                </td>
                <td class="has-text-centered">{{ entity.isBusinessDay ? 'Yes' : 'No' }}</td>
                <td class="has-text-right">{{ entity.productionSlot }}</td>
                <td>{{ $filters.formatTimeLocale(entity.openTime, $userInfo.locale) }}</td>
                <td>{{ $filters.formatTimeLocale(entity.closeTime, $userInfo.locale) }}</td>
                <!-- <td>{{ $filters.formatDateLocale(entity.modifiedDate, $userInfo.locale) }}</td> -->
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    data-tooltip="Click to edit"
                    @click="handleEdit(entity.companyOverrideBusinessHoursId)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </a>
                  <a class="button is-small is-danger is-inverted tooltip"
                    data-tooltip="Click to delete"
                    @click="remove(entity.companyOverrideBusinessHoursId)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>

            <button class="button is-success ml-1"
              @click="handleNew">
              <span class="icon">
                <i class="mdi mdi-plus mdi-18px" />
              </span>
              <span>New</span>
            </button>
          </article>
        </div>
      </div>
    </div>

    <override-business-hours-model v-if="isModalActive"
      :active.sync="isModalActive"
      :override-business-hours-id="selectedOverrideBusinessHoursId"
      :is-new="isNewOverrideBusinessHours"
      @close="closeModal" />

    <confirm-modal v-if="isConfirmModalActive"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Override Business Hours</p>
      <p slot="text-content">
        Business Hours
        <span class="has-text-primary has-text-weight-bold">{{ $filters.formatDateLocale(selectedEntity.date, $userInfo.locale) }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>

  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { OverrideBusinessHoursColumns } from './columns'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import StoreMixin from '../storeMixin'
import CompanyService from '../CompanyService'
import OverrideBusinessHoursModel from './OverrideBusinessHoursModel.vue'
import EventBus from '@/components/EventBus'
import { ConfirmModal } from '@/components/BulmaModal'

export default {
  name: 'CompanyOverrideBusinessHours',
  components: {
    BulmaTable,
    OverrideBusinessHoursModel,
    ConfirmModal
  },
  // filters: {
  //   formatBatchStatus(value) {
  //     const index = Object.values(OrmBatchStatusType).indexOf(value)
  //     const status = Object.keys(OrmBatchStatusType)[index]
  //     return status.replace(/([a-z])([A-Z])/g, '$1 $2')
  //         .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
  //         .replace(/^./, function(str) {
  //           return str.toUpperCase()
  //         })
  //   }
  // },
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin],
  props: {},
  data: () => {
    return {
      isTableLoading: false,
      totalRows: 0,
      selectedRow: null,
      selectedFromDate: null,
      selectedToDate: null,
      filter: {
        fromDate: null,
        toDate: null,
        sortColumn: OverrideBusinessHoursColumns[0].name,
        sortOrder: OverrideBusinessHoursColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      entities: null,
      isModalActive: false,
      selectedOverrideBusinessHoursId: null,
      isNewOverrideBusinessHours: false,
      isConfirmModalActive: false,

      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        timeAdjust: '00:00:00'
      }
    }
  },
  computed: {
    columns() {
      return OverrideBusinessHoursColumns
    },
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|CompanyOverrideBusinessHours|filter`
      } else {
        return ''
      }
    },
    selectedEntity() {
      if (this.selectedOverrideBusinessHoursId) {
        const found = this.entities.find((i) => i.companyOverrideBusinessHoursId == this.selectedOverrideBusinessHoursId)
        return found
      }
      return null
    }
  },
  // watch: {
  //   selectedFromDate(newVal, oldVal) {
  //     if (newVal) {
  //       this.filter.fromDate = newVal.toISOString()
  //     } else {
  //       this.filter.fromDate = null
  //     }
  //   },
  //   selectedToDate(newVal, oldVal) {
  //     if (newVal) {
  //       this.filter.toDate = newVal.toISOString()
  //     } else {
  //       this.filter.toDate = null
  //     }
  //   }
  // },
  created() {
    if (this.filter.fromDate) {
      this.selectedFromDate = new Date(this.filter.fromDate)
    }
    if (this.filter.toDate) {
      this.selectedToDate = new Date(this.valufiltere.toDate)
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    highlightSelected(index, event) {},
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.refresh()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.refresh()
    },
    filterList() {
      this.filter.pageIndex = 1
      this.refresh()
    },
    resetList() {
      this.filter.fromDate = null
      this.filter.toDate = null
      this.filter.sortColumn = OverrideBusinessHoursColumns[0].name
      this.filter.sortOrder = OverrideBusinessHoursColumns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.refresh()
    },
    async refresh() {
      this.persistFilter()
      await this.getOverrideBusinessHours()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    async getOverrideBusinessHours() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entities = await CompanyService.getOverrideBusinessHours(this.filter)
      this.totalRows = this.entities.length > 0 ? this.entities[0].totalRows : 0
      this.isTableLoading = false
    },
    async addNew() {
      this.addStoreItem()
    },
    closeModal() {
      this.reset()
      this.refresh()
    },
    handleEdit(id) {
      this.selectedOverrideBusinessHoursId = id
      this.isModalActive = true
      this.isNewOverrideBusinessHours = false
    },
    handleNew() {
      this.selectedOverrideBusinessHoursId = null
      this.isNewOverrideBusinessHours = true
      this.isModalActive = true
    },
    onFilterClick() {
      this.filterList()
    },
    onResetClick() {
      this.filter.fromDate = null
      this.filter.toDate = null
      this.filterList()
    },
    reset() {
      this.selectedOverrideBusinessHoursId = null
      this.isModalActive = false
      this.isConfirmModalActive = false
      this.isNewOverrideBusinessHours = false
    },
    remove(id) {
      this.selectedOverrideBusinessHoursId = id
      this.isConfirmModalActive = true
    },
    async deleteEntity(confirmDelete) {
      if (confirmDelete) {
        try {
          const result = await CompanyService.deleteEntityOverrideBusinessHours(this.selectedOverrideBusinessHoursId)
          //this.$notification.success('Override Business Hours', 'Override Business Hours deleted')
          const isSucess = result.isSuccess
          const msg = result.message || 'Open time or Close time is not allowed. Please select another time'
          this.$toast.open({
            message: isSucess ? 'Override Business Hours deleted' : msg,
            type: isSucess ? 'is-success' : 'is-danger',
            position: 'is-top',
            queue: false
          })
          this.reset()
          this.refresh()
        } catch (e) {
          EventBus.$emit('serviceError', e.response.status)
          //this.$notification.openMessageXhrError('Override Business Hours', e)
          this.$toast.open({
            message: e,
            type: 'is-danger',
            position: 'is-top',
            queue: false
          })
          return false
        }
      } else {
        this.reset()
      }
    }
  }
}
</script>